<template>
	<div id="company-list">
		<div class="company-header"><el-button type="primary" @click="openAddDialog">添加企业</el-button></div>
		<el-table :data="companyData" style="width: 100%"  stripe>
			<el-table-column align="center" prop="image" label="图片" width="180">
				<template slot-scope="scope">
					<img :src="scope.row.images" alt="图片" width="60" height="40" />
				</template>
			</el-table-column>
			<el-table-column align="center" prop="name" label="入驻企业名称"></el-table-column>

			<el-table-column align="center" prop="industry_labels" label="领域">
				<template slot-scope="scope">
					<el-tag size="medium" v-for="(item, i) in scope.row.industry_labels" :key="i">{{ item.title }}</el-tag>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="total_employees" label="员工总数(人)"></el-table-column>
			<el-table-column align="center" prop="total_capitals" label="注册资本总额(万元)"></el-table-column>
			<el-table-column align="center" prop="operation" label="操作">
				<template slot-scope="scope">
					<el-button @click="editCompany(scope.row)" type="primary" size="mini">编辑</el-button>
					<el-button @click="delCompany(scope.row)" type="danger" size="mini" >删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 添加弹窗 -->
		<el-dialog title="添加企业" :visible.sync="addDialog" width="60%" center>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
				<el-row :gutter="20">
					<el-col :span="11">
						<el-form-item label="企业图片" prop="images">
							<el-upload
								class="avatar-uploader"
								:action="URL.upLoad"
								:show-file-list="false"
								:on-success="res => $set(ruleForm, 'images', res.data.path)"
								:before-upload="beforeAvatarUpload"
								:headers="uploadHeader"
								accept="image/jpeg, image/jpg, image/png"
							>
								<img v-if="ruleForm.images" :src="ruleForm.images" class="avatar" />
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
							<div style="font-size:12px;line-height:18px;">
								<div>(仅上传1张图片)</div>
								<div>建议上传大小为1.3：1的图片</div>
								<div>仅支持jpg、jpeg、png格式大小,不能大于4M</div>
							</div>
						</el-form-item>
						<el-form-item label="入驻企业名称" prop="company_name"><el-input v-model="ruleForm.company_name"></el-input></el-form-item>
						
						<el-form-item label="领域" prop="industry_labels">
							<el-select v-model="ruleForm.industry_labels" placeholder="请选择领域" multiple style="width: 100%;">
								<el-option v-for="item in industryLabels" :key="item.id" :label="item.title" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="员工总数" prop="total_employees">
							<el-input type="number" v-model="ruleForm.total_employees">
								<template slot="append">人</template>
							</el-input>
						</el-form-item>
						<el-form-item label="注册资本总额" prop="total_capitals">
							<el-input type="number" v-model="ruleForm.total_capitals">
								<template slot="append">万元</template>
							</el-input>
						</el-form-item>
						<el-form-item label="入驻空间" prop="settle_space_id">
							<el-select v-model="ruleForm.settle_space_id" placeholder="请选择入驻空间" style="width: 100%;">
								<el-option v-for="item in spaceData" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="11" :offset="1">
						<el-form-item label="企业荣誉" prop="honor_id">
							<el-select v-model="ruleForm.honor_id" placeholder="请选择企业荣誉" multiple style="width: 100%;">
								<el-option v-for="item in honorsData" :key="item.id" :label="item.title" :value="item.id"></el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="融资" prop="if_financing">
							<el-radio-group v-model="ruleForm.if_financing">
								<el-radio :label="1">已融资</el-radio>
								<el-radio :label="0">未融资</el-radio>
							</el-radio-group>
						</el-form-item>

						<el-form-item label="融资轮次" prop="finance_id">
							<el-select v-model="ruleForm.finance_id" placeholder="请选择融资轮次" style="width: 100%;">
								<el-option v-for="item in financeData" :key="item.id" :label="item.title" :value="item.id"></el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="投资人" prop="investor_name"><el-input v-model="ruleForm.investor_name"></el-input></el-form-item>

						<el-form-item label="投资额度" prop="investor_quota"><el-input v-model="ruleForm.investor_quota" type="number"></el-input></el-form-item>

						<el-form-item label="销售收入" prop="sale_income">
							<el-select v-model="ruleForm.sale_income" placeholder="请选择销售收入" style="width: 100%;">
								<el-option v-for="item in saleIncomeData" :key="item.id" :label="item.title" :value="item.id"></el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="使用种子基金数" prop="seed_fund">
							<el-input type="number" v-model="ruleForm.seed_fund">
								<template slot="append">
									万元
								</template>
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
					<el-button @click="cancel('ruleForm')">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<!-- 分页组件 -->
		<div class="pages">
			<Page @currentPageChange="currentPageChange" :pageSize="pageData.per_page" :page="pageData.current_page" :total="pageData.total" style="text-align:center"></Page>
		</div>
	</div>
</template>
<script>
import Page from '@/components/PageComponents';
import { success, warning, error } from '@/utils/notification';
export default {
	name: 'company',
	components: {
		Page
	},
	data() {
		var checkNumber = (rule, value, callback) => {
			if (value < 0) {
				callback(new Error('输入不能为负数'));
			} else {
				callback();
			}
		};
		var checkSeedfund = (rule, value, callback) => {
			if (String(value).split('.')[1] && String(value).split('.')[1].length > 2) {
				callback(new Error('只能输入两位小数'));
				return;
			} else {
				callback();
			}
		};
		return {
			uploadHeader: {}, //上传头部
			companyData: [], //公司数据
			pageData: {
				//分页数据
				per_page: 0,
				current_page: 0,
				total: 0
			},
			nowPage: 1,
			addDialog: false, //弹窗
			ruleForm: {
				id: '',
				organ_id: '',
				images: '',
				total_employees: '',
				total_capitals: '',
				industry_labels: [],
				company_name: '',
				settle_space_id: '',
				if_financing: '',
				finance_id: '',
				investor_name: '',
				sale_income: '',
				seed_fund: '',
				honor_id: []
			}, //表单企业数据
			industries: [], //领域
			industryLabels: [], //获取的领域列表
			spaceData: [], //空间列表
			financeData: [], //融资轮次
			saleIncomeData: [], //销售收入
			honorsData: [], //企业荣誉
			rules: {
				company_name: [{ required: true, message: '请填写入驻企业数据', trigger: 'blur' }, { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'change' }],
				total_employees: [{ required: true, message: '请填写员工总数', trigger: 'blur' }, { validator: checkNumber, trigger: 'change' }],
				total_capitals: [{ required: true, message: '请填写注册资本总额', trigger: 'blur' }, { validator: checkSeedfund, trigger: 'change' }],
				settle_space_id: [{ required: true, message: '请选择入驻空间', trigger: 'change' }],
				if_financing: [{ required: true, message: '请选择融资', trigger: 'change' }],
				finance_id: [{ required: true, message: '请选择融资轮次', trigger: 'change' }],
				seed_fund: [{ required: true, message: '请输入种子基金数', trigger: 'blur' }, { validator: checkSeedfund, trigger: 'change' }],
				honor_id: [{ required: true, message: '请选择企业荣誉', trigger: 'blur' }],
				investor_quota: [{ validator: checkSeedfund, trigger: 'change' }]
			}
		};
	},
	created() {
		let token = sessionStorage.adminToken; // pc前台token
		this.uploadHeader.Authorization = 'Bearer ' + token;
		this.getCompanyList();
		this.getIndustry();
		this.getSpaceData();
		this.getFinanceData();
		this.getSaleIncomeData();
		this.getHonorsData();
	},
	methods: {
		//企业入驻--列表
		getCompanyList(page) {
			let id = sessionStorage.organizationId;
			this.$http
				.get(this.URL.adminUrl.organDetail + id, {
					params: {
						page
					}
				})
				.then(res => {
					this.companyData = res.data.data.data;
					this.pageData = res.data.data.meta;
				});
		},
		//获取企业荣誉
		getHonorsData() {
			this.$http.get(this.URL.adminUrl.honorsList).then(res => {
				this.honorsData = res.data.data;
			});
		},
		//获取融资轮次数据
		getFinanceData() {
			this.$http.get(this.URL.adminUrl.stagesList).then(res => {
				this.financeData = res.data.data;
			});
		},
		//获取销售收入数据
		getSaleIncomeData() {
			this.$http.get(this.URL.adminUrl.salesList).then(res => {
				this.saleIncomeData = res.data.data;
			});
		},
		//获取空间数据
		getSpaceData() {
			let id = sessionStorage.organizationId;
			this.$http.get(this.URL.adminUrl.spaceList + id + '/spaces').then(res => {
				this.spaceData = res.data.data.spaces;
			});
		},
		//获取领域数据
		getIndustry() {
			this.$http.get(this.URL.industry, {}).then(res => {
				this.industryLabels = res.data.data;
			});
		},
		//分页切换
		currentPageChange(page) {
			this.getCompanyList(page);
			this.nowPage = page;
		},
		//打开新增弹窗
		openAddDialog() {
			this.addDialog = true;
			this.ruleForm = {};
			if (this.$refs.ruleForm) this.$refs.ruleForm.resetFields();
		},
		//打开编辑弹窗
		editCompany(data) {
			this.addDialog = true;
			this.ruleForm.id = data.id;
			this.companyDetail(data.id);
		},
		//取消弹窗
		cancel(formName) {
			this.addDialog = false;
		},
		//确定弹窗
		submitForm(formName) {
			if (!this.ruleForm.images) {
				warning('至少上传一张图片');
			} else {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.pubilicIn();
					} else {
						return false;
					}
				});
			}
		},

		//删除企业入驻
		delCompany(data) {
			this.$confirm(' 是否删除该企业?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$http.delete(this.URL.adminUrl.delCompany + data.id, {}).then(res => {
						this.getCompanyList(this.nowPage);
					});
					success('删除成功');
				})
				.catch(() => {});
		},

		//图片上传前的事件
		beforeAvatarUpload(file) {
			var testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
			const isLt4M = file.size / 1024 / 1024 <= 4; //图片大小不超过4MB
			if (!testmsg) {
				error('上传图片格式不对!');
				return;
			}
			if (!isLt4M) {
				error('上传图片大小不能超过 4M!');
				return;
			}
			return testmsg && isLt4M;
		},
		// 企业详情接口
		companyDetail(id) {
			this.$http.get(this.URL.adminUrl.companyDetail + id).then(res => {
				this.ruleForm = res.data.data;
				this.ruleForm.industry_labels = this.ruleForm.industry_labels.map(item => item.id);
				this.ruleForm.honor_id = this.ruleForm.honor_id.map(item => item.id);
			});
		},

		//入驻添加编辑公共接口
		pubilicIn() {
			let organ_id = sessionStorage.organizationId;
			this.ruleForm.organ_id = organ_id;
			this.$http.post(this.URL.adminUrl.organAddEdit, this.ruleForm).then(res => {
				this.addDialog = false;
				if (this.ruleForm.id) {
					success('企业编辑成功');
					this.getCompanyList(this.nowPage);
				} else {
					this.getCompanyList();
					success('企业添加成功');
				}
			});
		}
	}
};
</script>
<style lang="less">
#company-list {
	//上传图片样式
	.avatar-uploader .el-upload {
		border: 2px dashed #d9d9d9;
		border-radius: 10px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 100px;
		height: 100px;
		line-height: 100px;
		text-align: center;
	}
	.avatar {
		width: 100px;
		height: 100px;
		display: block;
	}
	//去掉数字输入框箭头
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}
	//头部按钮
	.company-header {
		float: right;
		margin: 10px 0 30px;
	}
	
	.pages {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 50px 0;
	}
}
</style>
